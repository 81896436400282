import { createClient } from "urql";
import log from "modules/log";

export const urqlClient = createClient({
  url: process.env.REACT_APP_API_URL,
  fetchOptions: {
    credentials: "include",
  },
  requestPolicy: "network-only",
});

export const loginStatusFromServer = () => {
  return urqlClient
    .query(` query { userLoggedIn } `)
    .toPromise()
    .then((result) => {
      if (result.error) {
        log.error(`GQL Server response error: ${result.error}`);
        return;
      }
      return result.data.userLoggedIn;
    });
};

export const logoutFromServer = () => {
  return urqlClient
    .mutation(
      ` mutation { logout {
          id role waiter_id display_name email phone message su
          waiter {
            id
            first_name
            middle_name
            last_name
            wallet_id
            wallet_status_cache
            wallet_balance_cache
            wallet_balance_calculated
            wallet_max_charge_limit_cache
            has_duplicate_transactions
            soap_wallet_id
            soap_wallet_balance
            soap_wallet_locked
            type
            w1_offer
            w1_wallet_status
            w1_wallet_id
            w1_wallet_balance
            w1_wallet_balance_updated_at
          }
        }}
      `
    )
    .toPromise()
    .then((result) => {
      if (result.error) {
        log.error(`GQL Server response error: ${result.error}`);
        return;
      }
      if (result.data.logout.id === "0") {
        // log.error(`Auth module logoutFromServer(): Logged out from server`)
        return { id: "0" };
      } else if (result.data.logout.id) {
        // log.error(`Auth module logoutFromServer(): SU switch`)
        return result.data.logout;
      }
    });
};

export const loggedUserInfo = () => {
  const query = `#gql
    { user
      {
        id
        role
        waiter_id
        display_name
        email
        phone
        created_at
        verified_at
        su
        alert
        offer
        w1_offer
        type
        waiter {
          id
          first_name
          middle_name
          last_name
          wallet_id
          wallet_status_cache
          wallet_balance_cache
          wallet_balance_calculated
          wallet_max_charge_limit_cache
          has_duplicate_transactions
          soap_wallet_id
          soap_wallet_balance
          soap_wallet_locked
          type
          w1_offer
          w1_wallet_status
          w1_wallet_id
          w1_wallet_balance
          w1_wallet_balance_updated_at
        }
      }
    }
  `;
  return urqlClient
    .query(query)
    .toPromise()
    .then((result) => {
      if (result.error) {
        console.error(`GQL Server response error: ${result.error}`);
        return;
      }
      // console.log(`Auth module loginStatusFromServer(): %c${ result.data.userLoggedIn? "Logged In" : "NOT logged In" } `,`color:${result.data.userLoggedIn?"green":"red"}`);
      return result.data.user;
    });
};
