import util from 'util'

const logInfo = (msg) => {
  if ( process.env.NODE_ENV !== 'production' ) {
    console.log(msg)
  }
}

const logError = (msg) => {
  window.logError(msg)
  if ( process.env.NODE_ENV !== 'production' ) {
    console.error(msg)
  }
}

const logErrorDump = (msg) => {
  logError(util.inspect(msg))
}

export default {
  error: logError,
  errorDump: logErrorDump,
  info: logInfo,
  log: logInfo,
}

