// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import {urqlClient} from 'modules/gql-client'
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'urql';
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

// const containerStyle = {
//   zIndex: 1999
// };

ReactDOM.render(
  <Provider value={urqlClient}>
    <App />
    {/* <ToastContainer position="top-right" autoClose={10000} style={containerStyle}/> */}
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
